<template>
    <div>
        <v-dialog v-model="editDialog" width="1000px">
        <v-card height="100%">
            <v-toolbar flat dense color="blue grey darken-4 white--text" tile>
                <v-icon large dark>mdi-text-box-edit-outline</v-icon>
                <h2
                    class="white--text mb-1"
                    style="font-family:'Brush Script MT',cursive;font-name:font-size:15px"
                    >Edit Data
                </h2>
            <v-spacer />
        <v-icon dark @click="close()">mdi-close</v-icon>
    </v-toolbar>
   
        <v-card-text>
            <v-row align="center" class="mt-n1">
                <v-col>
                <v-text-field
                    readonly
                    dense
                    label="Code Number"
                    plain
                    outlined
                    v-model="edit.code_number"
            >
            </v-text-field>
        </v-col>
        <v-col>
            <v-text-field
                plain
                outlined
                dense
                v-model="edit.customerName"
                label="Customer Name"
                readonly
                >
            </v-text-field>
        </v-col>
        <v-col>
                <v-text-field
                plain
                outlined
                dense
                v-model="edit.exhibitionBranch"
                label="Exhibition Branch Name"
                readonly
                >
            </v-text-field>
        </v-col>
            <v-col>
                <v-text-field
                plain
                outlined
                dense
                v-model="edit.subconFaxNo"
                label="Fax No."
                readonly
                >
            </v-text-field>
        </v-col>
    </v-row> 
    <!-- test -->
    <v-row align="center" class="mt-n9">
        <v-col class="mt-n7">
            <v-menu
                v-model="menu1"
                readonly
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
        <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    class="mb-n7"
                    plain
                    outlined
                    dense
                    v-model="edit.joutou"
                    label="Joutou Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="#1F2833"
                    />
        </template>
                <v-date-picker
                    readonly
                    v-model="edit.joutou"
                    header-color="#1F2833"
                    scrollable
                    @input="menu1 = false"
                >    
                </v-date-picker>
            </v-menu>
        </v-col>
        <v-col class="mt-n7">
            <v-menu
                v-model="menu2"
                readonly
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
        <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    class="mb-n7"
                    plain
                    outlined
                    dense
                    v-model="edit.lessjoutouDate"
                    label="Less Joutou Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="#1F2833"
                />
        </template>
                <v-date-picker
                    readonly
                    v-model="edit.lessjoutouDate"
                    header-color="#1F2833"
                    scrollable
                    @input="menu2 = false"
                >    
                </v-date-picker>
            </v-menu>
        </v-col>
        <v-col>
            <v-text-field
                readonly
                dense
                label="New Subcon"
                plain
                outlined
                v-model="edit.subcon318"
            >
            </v-text-field>
        </v-col>
        <v-col>
            <v-text-field
                readonly
                dense
                label="Subcon Name"
                plain
                outlined
                v-model="edit.subconName"
                >
            </v-text-field>
        </v-col>
        </v-row>    

        <!--ANCHOR ArrayReference -->

        <v-row align="center" class="mt-n9">
            <v-col v-for="(item, i) in reference" :key="i">
                <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th
                        v-for="(header, h) in Object.keys(item[0]).filter(
                            (el) => el != 'tittle'
                            )"
                            :key="h"
                            class="text-center"
                        >
                        {{ header.toUpperCase() }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(body, b) in item" :key="b">
                        <td width="35%" class="text-center">{{ body.contract }}</td>
                        <td width="35%" class="text-center">{{ body.amphere }}</td>
                        <td width="30%" class="text-center">{{ body.mb }}</td>
                    </tr>
                    </tbody>
                </template>
                </v-simple-table>
            </v-col>
        </v-row>

                <!-- ANCHOR ArrayProposeKansen-->

                <v-toolbar class="mt-5" flat dense color="blue grey darken-4 white--text" tile>
                <v-row>
                    <v-col class="d-flex justify-space-around">
                    <center>
                        <span
                        class="white--text mb-3"
                        style="font-family:'Georgia',cursive;font-name:font-size:50px"
                        ><strong>BELOW 100 AMPHERE</strong></span
                        >
                        <br />
                        <span
                        class="white--text mt-n1"
                        style="font-family:'Georgia',cursive;font-name:font-size:50px"
                        ><strong>Proposed Kansen</strong></span
                        >
                    </center>
                    </v-col>
                </v-row>
                </v-toolbar>
                <v-row align="center">
                <v-col v-for="(item, i) in proposeKansen" :key="i">
                    <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th
                            v-for="(header, h) in Object.keys(item[0])"
                            :key="h"
                            class="text-center"
                            >
                            {{ header.toUpperCase() }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(body, b) in item" :key="b">
                            <td width="35%" class="text-center" id="edit.lenght_Ksc1">
                            {{ getDivision ? getDivision : "" }}
                            </td>
                            <td width="35%" class="text-center" id="size_ksc1">
                            {{ size_ksc1 }}
                            </td>
                            <td width="30%" class="text-center" id="mb_ksc1">{{ mb_ksc1 }}</td>
                        </tr>
                        <tr>
                            <td width="35%" class="text-center">電圧降下</td>
                            <td width="35%" class="text-center" id="size_ksc2">
                            {{ formatPrice(comTotal(getDivision, size_ksc1, mb_ksc1)) }}
                            </td>
                            <td width="30%" class="text-center" id="mb_ksc2" style="color: red">
                            {{ com4Val }}
                            </td>
                        </tr>
                        </tbody>
                    </template>
                    </v-simple-table>
                </v-col>
                </v-row>

                <!--ANCHOR 幹線-->

                <v-toolbar class="mt-5" flat dense color="blue grey darken-4 white--text" tile>
                <v-row align="center">
                    <v-col cols="10" class="d-flex justify-space-around">
                    <v-sheet
                        flat
                        dense
                        color="blue grey darken-4 white--text"
                        style="margin-left: 150px"
                        tile
                    >
                        <strong>幹線</strong>
                    </v-sheet>
                    </v-col>
                   
                    <v-col class="d-flex justify-space-around">
                    <v-sheet flat dense color="blue grey darken-4 white--text" tile>
                       
                    </v-sheet>
                    </v-col>
                </v-row>
                </v-toolbar>
                <v-row align="center">
                <v-col v-for="(item, i) in mainLine" :key="i">
                    <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th colspan="2"></th>
                            <th
                            v-for="(header, h) in Object.keys(item[0])"
                            :key="h"
                            class="text-center"
                            :style="{ color: header === 'アース棒' ? '#00B0FF' : 'white' }"
                            >
                            {{ header.toUpperCase() }}
                            </th>
                        </tr>
                        </thead>

        <!-- NOTE BODY TABLE FROM KANSEN -->
                        
                        <tbody>
                        <tr v-for="(body, b) in item" :key="b">
                            <td rowspan="2" class="text-center">参考値</td>
                            <td class="text-center">電灯</td>
                            <td class="text-center" width="14%">
                                <v-combobox
                                    v-model="edit.cc_c1"
                                    @change="updatecc1(edit.cc_c1)"
                                    :items="selectionCategory"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                    :readonly="dialogType"
                                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                >
                                </v-combobox>
                            </td>
                            <td class="text-center" width="13%">
                            <v-text-field
                                v-model="kansen_blankc1"
                                hide-details
                                plain
                                outlined
                                dense
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="14%">
                            <v-combobox
                                v-model="edit.sizeCV_c1"
                                @change="updatesizeCV1(edit.sizeCV_c1)"
                                :items="selectionCvt"
                                hide-details
                                plain
                                outlined
                                dense
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-combobox>
                            </td>
                            <td class="text-center" width="10%">
                            <v-text-field
                                v-model="quantity_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                            <v-text-field
                                v-model="edit.Value_c1"
                                @keyup="calculate(edit.Value_c1)"
                                @blur="calculate(edit.Value_c1)"
                                hide-details
                                plain
                                outlined
                                dense
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            >
                            </v-text-field>
                            </td>
                            <td class="text-center" width="13%">
                            <v-text-field
                                v-model="wa_c1"
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                hide-details
                                plain
                                outlined
                                dense
                            ></v-text-field>
                            </td>
                            <td rowspan="2" class="text-center" width="20%">
                            <v-text-field
                                v-model="eb_c1"
                                height="100%"
                                hide-details
                                plain
                                outlined
                                 :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <!-- <td class="text-center">余剰</td> -->
                            <td class="text-center" v-if="edit.type !='Zenryou'">余剰</td>
                            <td class="text-center" v-else>全量</td>
                            <td class="text-center" width="17%">
                            <v-text-field
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="edit.cc_c2"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                            <v-text-field
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="edit.kansen_blankc2"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="17%">
                            <v-text-field
                                v-if="edit.type !='Zenryou'"
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="edit.sizeCV_c2"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            <v-combobox
                                v-else
                                v-model="edit.sizeCV_c2"
                                @change="updatesizeCV1(edit.sizeCV_c2)"
                                :items="selectionCvt"
                                hide-details
                                plain
                                outlined
                                dense
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-combobox>
                            </td>
                            <td class="text-center" width="12%"  v-if="edit.type !='Zenryou'">
                            <v-text-field
                                readonly
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="12%"  v-else>
                                <v-text-field
                                    :readonly="dialogType"
                                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                    v-model="edit.quantity_c2"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                                <v-text-field
                                    :readonly="dialogType"
                                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                    v-model="edit.Value_c2"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                                    <v-text-field
                                        :readonly="dialogType"
                                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                        v-model="edit.wa_c2"
                                        plain
                                        outlined
                                        dense
                                        hide-details
                                    ></v-text-field>
                            </td>
                        </tr>
                        </tbody>

                    <!-- NOTE  BODY TABLE FROM SUBCON -->

                        <tbody v-if="edit.first_request_received_date != null" >
                        <tr>
                            <td rowspan="2" class="text-center">発注時</td>
                            <td class="text-center">電灯</td>
                            <td class="text-center" width="14%">
                            <v-text-field
                                v-model="dataFromSubcon.cc_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="13%">
                            <v-text-field
                                
                                v-model="dataFromSubcon.kansen_blankc1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="14%">
                            <v-text-field
                                v-model="dataFromSubcon.sizeCV_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="13%">
                            <v-text-field
                                
                                v-model="dataFromSubcon.quantity_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                            <v-text-field
                                
                                v-model="dataFromSubcon.Value_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="13%">
                            <v-text-field
                               
                                v-model="dataFromSubcon.wa_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td rowspan="2" class="text-center" width="20%">
                            <v-text-field
                                
                                v-model="dataFromSubcon.eb_c1"
                                height="100%"
                                hide-details
                                plain
                                outlined
                                readonly
                            ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <!-- <td class="text-center">余剰</td> -->
                            <td class="text-center" v-if="edit.type !='Zenryou'">余剰</td>
                            <td class="text-center" v-else>全量</td>
                            <td class="text-center" width="17%">
                            <v-text-field
                               
                                v-model="dataFromSubcon.cc_c2"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                            <v-text-field
                              
                                v-model="dataFromSubcon.kansen_blankc2"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="17%">
                            <v-text-field
                                v-model="dataFromSubcon.sizeCV_c2"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>

                            <td class="text-center" width="12%" v-if="dataFromSubcon.type !='Zenryou'">
                                <v-text-field
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-text-field>
                            </td>
                            
                            <td class="text-center" width="12%" v-else>
                                <v-text-field
                                    v-model="dataFromSubcon.quantity_c2"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                            <v-text-field
                                
                                v-model="dataFromSubcon.Value_c2"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                            <v-text-field
                              
                                v-model="dataFromSubcon.wa_c2"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                    </v-simple-table>
                </v-col>
                </v-row>

                <!-- ANCHOR 太陽光用 -->

                <v-toolbar class="mt-5" flat dense color="blue grey darken-4 white--text" tile>
                    <v-row align="center">
                        <v-col cols="10" class="d-flex justify-space-around">
                        <v-sheet
                            flat
                            dense
                            color="blue grey darken-4 white--text"
                            style="margin-left: 150px"
                            tile
                        >
                            <strong>太陽光用</strong>
                        </v-sheet>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-row align="center">
                <v-col v-for="(item, i) in forsunLight" :key="i">
                    <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th
                            v-for="(header, h) in Object.keys(item[0])"
                            :key="h"
                            class="text-center"
                            >
                            {{ header.toUpperCase() }}
                            </th>
                        </tr>
                        </thead>

<!-- NOTE  BODY TABLE FROM KANSEN -->
            <tbody>
            <tr v-for="(body, b) in item" :key="b">
                <td rowspan="6" width="1" class="text-center">参考値</td>
            </tr>
            <tr>
                <td width="20%" class="text-center">
                    <tr>
                        <div class="select-wrapper">
                            <v-checkbox
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="edit.ic_c1r3"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-checkbox>
                            <v-combobox
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="edit.ic_c2r3"
                                :items="selectionKw"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-combobox>
                        </div>
                    </tr>
                </td>
                <td width="20%" class="text-center">
                    <tr>
                        <div class="select-wrapper">
                            <v-checkbox
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="edit.size_c3r3"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-checkbox>
                            <v-combobox
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="edit.size_c4r3"
                                :items="selectionCv"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-combobox>
                        </div>
                    </tr>
                </td>
                <td class="text-center">
                    <v-text-field
                    :readonly="dialogType"
                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
                <td class="text-center">
                <v-text-field
                    :readonly="dialogType"
                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    v-model="edit.lpi_6r3"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
                <td class="text-center">
                <v-text-field
                    :readonly="dialogType"
                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    v-model="edit.fl_c7r3"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
                <!-- single text field -->
                <td class="text-center" rowspan="5">
                        <v-text-field
                            :readonly="dialogType"
                            :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            v-model="eb_c1r1"
                            plain
                            outlined
                            dense
                            hide-details
                        ></v-text-field>
                </td>
            <tr>
                <td width="14%" class="text-center">
                <tr>
                    <div class="select-wrapper">
                    <v-checkbox
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                        v-model="edit.ic_c1r5"
                        plain
                        outlined
                        dense
                        hide-details
                    ></v-checkbox>
                    <v-combobox
                        :items="selectionKw"
                        plain
                        outlined
                        dense
                        hide-details
                        v-model="edit.ic_c2r5"
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    ></v-combobox>
                    </div>
                </tr>
                </td>
                <td width="14%" class="text-center">
                <tr>
                    <div class="select-wrapper">
                    <v-checkbox
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                        plain
                        outlined
                        dense
                        hide-details
                        v-model="edit.size_c3r5"
                    ></v-checkbox>
                    <v-combobox
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                        :items="selectionCv"
                        plain
                        outlined
                        dense
                        hide-details
                        v-model="edit.size_c4r5"
                    ></v-combobox>
                    </div>
                </tr>
                </td>
                <td class="text-center">
                <v-text-field
                    :readonly="dialogType"
                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    v-model="edit.total_5r5"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
                <td class="text-center">
                <v-text-field
                    :readonly="dialogType"
                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    v-model="edit.lpi_6r5"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
                <td class="text-center">
                <v-text-field
                    :readonly="dialogType"
                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    v-model="edit.fl_c7r5"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
            

            
            <tr>
                <td width="14%" class="text-center">
                <tr>
                    <div class="select-wrapper">
                    <v-checkbox
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                        v-model="edit.ic_c1r6"
                        plain
                        outlined
                        dense
                        hide-details
                    ></v-checkbox>
                    <v-combobox
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                        large
                        :items="selectionKw"
                        plain
                        outlined
                        dense
                        hide-details
                        v-model="edit.ic_c2r6"
                    ></v-combobox>
                    </div>
                </tr>
                </td>
                <td width="14%" class="text-center">
                <tr>
                    <div class="select-wrapper">
                    <v-checkbox
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                        v-model="edit.size_c3r6"
                        plain
                        outlined
                        dense
                        hide-details
                    ></v-checkbox>
                    <v-combobox
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                        :items="selectionCv"
                        plain
                        outlined
                        dense
                        hide-details
                        v-model="edit.size_c4r6"
                    ></v-combobox>
                    </div>
                </tr>
                </td>
                <td class="text-center">
                <v-text-field
                    :readonly="dialogType"
                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    v-model="edit.total_5r6"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
                <td class="text-center">
                <v-text-field
                    :readonly="dialogType"
                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    v-model="edit.lpi_6r6"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
                <td class="text-center">
                <v-text-field
                    :readonly="dialogType"
                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    v-model="edit.fl_c7r6"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
            </tr>



            <tr>
                <td width="5%" class="text-center">
                <span>蓄電池自立用</span>
                </td>
                <td width="14%" class="text-center">
                <tr>
                    <div class="select-wrapper">
                    <v-checkbox
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                        v-model="edit.size_c3r7"
                        plain
                        outlined
                        dense
                        hide-details
                    ></v-checkbox>
                    <v-combobox
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                        v-model="edit.size_c4r7"
                        :items="selectionCv"
                        plain
                        outlined
                        dense
                        hide-details
                    ></v-combobox>
                    </div>
                </tr>
                </td>
                <td class="text-center">
                <v-text-field
                    
                    v-model="total_5r7"
                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    :readonly="dialogType"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
                <td class="text-center">
                <v-text-field
                    :readonly="dialogType"
                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    v-model="edit.lpi_6r7"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
                <td class="text-center">
                <v-text-field
                    :readonly="dialogType"
                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    v-model="edit.fl_c7r7"
                    plain
                    outlined
                    dense
                    hide-details
                ></v-text-field>
                </td>
            </tr>


            <tr>
                <td width="5%" class="text-center">
                <span>給電アダプタ</span>
                </td>
                <td width="14%" class="text-center">
                <tr>
                    <div class="select-wrapper">
                    <v-checkbox
                       
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                        v-model="cbForcv55"
                        plain
                        outlined
                        dense
                        hide-details
                    ></v-checkbox>
                    <v-combobox
                        v-model="edit.newCv55"
                        :items="selectionCv"
                        :readonly="dialogType"
                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                        plain
                        outlined
                        dense
                        hide-details
                    ></v-combobox>
                    </div>
                </tr>
                </td>
                <td class="text-center">
                <v-text-field
                :readonly="dialogType"
                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    plain
                    outlined
                    dense
                    hide-details
                   
                    v-model="edit.total_cv55_3"
                ></v-text-field>
                </td>
                <td class="text-center">
                <v-text-field
               
                    :readonly="dialogType"
                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    plain
                    outlined
                    dense
                    hide-details
                    v-model="edit.lpi_cv55_4"
                ></v-text-field>
                </td>
                <td class="text-center">
                <v-text-field
               
                    :readonly="dialogType"
                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                    plain
                    outlined
                    dense
                    hide-details
                    v-model="edit.fl_cv55_5"
                ></v-text-field>
                </td>
            </tr>
            </tbody>
                    


        <!-- NOTE  BODY TABLE FROM SUBCON -->

        <tbody v-if="edit.first_request_received_date != null">
                        <tr v-for="(body, b) in item" :key="b">
                            <td rowspan="6" width="1" class="text-center">発注時</td>
                        </tr>
                        <tr>
                            <td width="20%" class="text-center">
                                <tr>
                                    <div class="select-wrapper">
                                        <v-checkbox
                                            v-model="dataFromSubcon.ic_c1r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                        ></v-checkbox>
                                        <v-combobox
                                            v-model="dataFromSubcon.ic_c2r3"
                                            :items="selectionKw"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                        ></v-combobox>
                                    </div>
                                </tr>
                            </td>
                            <td width="20%" class="text-center">
                                <tr>
                                    <div class="select-wrapper">
                                        <v-checkbox
                                            v-model="dataFromSubcon.size_c3r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                        ></v-checkbox>
                                        <v-combobox
                                            v-model="dataFromSubcon.size_c4r3"
                                            :items="selectionCv"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                        ></v-combobox>
                                    </div>
                                </tr>
                            </td>
                            <td class="text-center">
                                <v-text-field
                                    v-model="dataFromSubcon.total_5r3"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                v-model="dataFromSubcon.lpi_6r3"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                v-model="dataFromSubcon.fl_c7r3"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <!-- single text field -->
                            <td class="text-center" rowspan="5">
                                    <v-text-field
                                        v-model="eb_c1r1"
                                        plain
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                    ></v-text-field>
                            </td>
                        <tr>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    v-model="dataFromSubcon.ic_c1r5"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-checkbox>
                                <v-combobox
                                    :items="selectionKw"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="dataFromSubcon.ic_c2r5"
                                    readonly
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    readonly
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="dataFromSubcon.size_c3r5"
                                ></v-checkbox>
                                <v-combobox
                                    readonly
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="dataFromSubcon.size_c4r5"
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly
                                v-model="dataFromSubcon.total_5r5"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly
                                v-model="dataFromSubcon.lpi_6r5"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly    
                                v-model="dataFromSubcon.fl_c7r5"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                         

                        
                        <tr>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    readonly
                                    v-model="dataFromSubcon.ic_c1r6"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                ></v-checkbox>
                                <v-combobox
                                    large
                                    :items="selectionKw"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="dataFromSubcon.ic_c2r6"
                                    readonly
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    readonly
                                    v-model="dataFromSubcon.size_c3r6"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                ></v-checkbox>
                                <v-combobox
                                    readonly
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="dataFromSubcon.size_c4r6"
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                v-model="dataFromSubcon.total_5r6"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly
                                v-model="dataFromSubcon.lpi_6r6"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly
                                v-model="dataFromSubcon.fl_c7r6"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                        </tr>



                        <tr>
                            <td width="5%" class="text-center">
                            <span>蓄電池自立用</span>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    v-model="dataFromSubcon.size_c3r7"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-checkbox>
                                <v-combobox
                                    v-model="dataFromSubcon.size_c4r7"
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                v-model="total_5r7"
                                readonly
                                plain
                                outlined
                                dense
                                hide-details
                                
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                              
                                v-model="dataFromSubcon.lpi_6r7"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                
                                v-model="dataFromSubcon.fl_c7r7"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                        </tr>


                        <tr>
                            <td width="5%" class="text-center">
                            <span>給電アダプタ</span>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    v-model="cbForcv55"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-checkbox>
                                <v-combobox
                                    v-model="dataFromSubcon.newCv55"
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly
                                plain
                                outlined
                                dense
                                hide-details
                                v-model="dataFromSubcon.total_cv55_3"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                            readonly
                                plain
                                outlined
                                dense
                                hide-details
                                v-model="dataFromSubcon.lpi_cv55_4"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                plain
                                outlined
                                dense
                                hide-details
                                v-model="dataFromSubcon.fl_cv55_5"
                                readonly
                            ></v-text-field>
                            </td>
                        </tr>
        </tbody>

                    </template>
                    </v-simple-table>








<!-- //NOTE NISETAI TABLE FOR KANSEN REQUEST -->


                    <v-toolbar class="mt-7"  dense color="red darken-4 white--text" tile v-if="edit.nisetai> 1">
                        <v-toolbar-title style="margin-left:45%;font-size:25px">2世帯電気工事</v-toolbar-title>
                        <v-icon v-if="!generatedNisetai" style="margin-left:25%" size="45" color="warning" @click="generateNisetaiData()">mdi-chevron-down</v-icon>
                    </v-toolbar>
                    
                <v-toolbar class="mt-5" flat dense color="blue grey darken-4 white--text" tile v-if="edit.nisetai > 1 && generatedNisetai">
                    <v-row>
                        <v-col class="d-flex justify-space-around">
                        <center>
                            <span
                            class="white--text mb-3"
                            style="font-family:'Georgia',cursive;font-name:font-size:50px"
                            ><strong>BELOW 100 AMPHERE</strong></span
                            >
                            <br/>
                            <span
                            class="white--text mt-n1"
                            style="font-family:'Georgia',cursive;font-name:font-size:50px"
                            ><strong>Proposed Kansen</strong></span
                            >
                        </center>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-row align="center" v-if="edit.nisetai > 1  && generatedNisetai">
                <v-col v-for="(item, i) in proposeKasenNisetai" :key="i">
                    <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th
                            v-for="(header, h) in Object.keys(item[0])"
                            :key="h"
                            class="text-center"
                            >
                            {{ header.toUpperCase() }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(body, b) in item" :key="b">
                            <td width="35%" class="text-center" id="nisetaiDataOfKansen.lenght_Ksc1">
                            {{nisetaiGetDivision ? nisetaiGetDivision : "" }}
                            </td>
                            <td width="35%" class="text-center" id="nisetai_size_ksc1">
                                {{nisetai_size_ksc1}}
                            
                            </td>
                            <td width="30%" class="text-center" id="nisetai_mb_ksc1">{{ nisetai_mb_ksc1 }}</td>
                        </tr>
                        <tr>
                            <td width="35%" class="text-center">電圧降下</td>
                            <td width="35%" class="text-center" id="size_ksc2">
                            {{ formatPrice(nisetaiComTotal(nisetaiGetDivision,  nisetai_size_ksc1,  nisetai_mb_ksc1)) }}
                            </td>
                            <td width="30%" class="text-center" id="nisetai_mb_ksc2" style="color: red">
                            {{ nisetaiCom4Val }}
                            </td>
                        </tr>
                        </tbody>
                    </template>
                    </v-simple-table>
                </v-col>
                </v-row>

                <!-- //NOTE SECOND TABLE FOR NISETAI -->

                <v-toolbar class="mt-5" flat dense color="blue grey darken-4 white--text" tile v-if="edit.nisetai > 1  && generatedNisetai">
                <v-row align="center">
                    <v-col cols="10" class="d-flex justify-space-around">
                    <v-sheet
                        flat
                        dense
                        color="blue grey darken-4 white--text"
                        style="margin-left: 150px"
                        tile
                    >
                        <strong>幹線</strong>
                    </v-sheet>
                    </v-col>
                    
                    <v-col class="d-flex justify-space-around">
                    <v-sheet flat dense color="blue grey darken-4 white--text" tile>
                      
                    </v-sheet>
                    </v-col>
                </v-row>
                </v-toolbar>
                <v-row align="center" v-if="edit.nisetai> 1  && generatedNisetai">
                <v-col v-for="(item, i) in mainLine" :key="i">
                    <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th colspan="2"></th>
                            <th
                            v-for="(header, h) in Object.keys(item[0])"
                            :key="h"
                            class="text-center"
                            :style="{ color: header === 'アース棒' ? '#00B0FF' : 'white' }"
                            >
                            {{ header.toUpperCase() }}
                            </th>
                        </tr>
                        </thead>


                        <tbody v-if="edit.nisetai > 1  && generatedNisetai">
                        <tr v-for="(body, b) in item" :key="b">
                            <td rowspan="2" class="text-center">参考値</td>
                            <td class="text-center">電灯</td>
                            <td class="text-center" width="14%">
                                <v-combobox
                                    v-model="nisetaiDataOfKansen.nisetaiCc_c1"
                                    @change="nisetaiUpdatecc1(edit.cc_c1)"
                                    :items="selectionCategory"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                    :readonly="dialogType"
                                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                >
                                </v-combobox>
                            </td>
                            <td class="text-center" width="13%">
                            <v-text-field
                                v-model="nisetaiDataOfKansen.nisetai_kansen_blankc1"
                                hide-details
                                plain
                                outlined
                                dense
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="14%">
                            <v-combobox
                                v-model="nisetaiDataOfKansen.sizeCV_c1"
                                @change="nisetaiUpdatesizeCV1(nisetaiDataOfKansen.sizeCV_c1)"
                                :items="selectionCvt"
                                hide-details
                                plain
                                outlined
                                dense
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-combobox>
                            </td>
                            <td class="text-center" width="10%">
                            <v-text-field
                                v-model="nisetaiDataOfKansen.quantity_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                            <v-text-field
                                v-model="nisetaiDataOfKansen.Value_c1"
                                @keyup="nisetaiCalculate(nisetaiDataOfKansen.Value_c1)"
                                @blur="nisetaiCalculate(nisetaiDataOfKansen.Value_c1)"
                                hide-details
                                plain
                                outlined
                                dense
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            >
                            </v-text-field>
                            </td>
                            <td class="text-center" width="13%">
                            <v-text-field
                                v-model="nisetaiDataOfKansen.wa_c1"
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                hide-details
                                plain
                                outlined
                                dense
                            ></v-text-field>
                            </td>
                            <td rowspan="2" class="text-center" width="20%">
                            <v-text-field
                                v-model="nisetaiDataOfKansen.eb_c1"
                                height="100%"
                                hide-details
                                plain
                                outlined
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                        </tr>
                        <tr>

                            <td class="text-center" v-if="edit.type !='Zenryou'">余剰</td>
                            <td class="text-center" v-else>全量</td>
                            <td class="text-center" width="17%">
                            <v-text-field
                                 :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="nisetaiDataOfKansen.cc_c2"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                            <v-text-field
                                 :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="nisetaiDataOfKansen.kansen_blankc2"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="17%">
                            <v-text-field
                            v-if="edit.type != 'Zenryou'"
                                 :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="nisetaiDataOfKansen.sizeCV_c2"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            <v-combobox
                                v-else
                                v-model="nisetaiDataOfKansen.sizeCV_c2"
                                @change="nisetaiUpdatesizeCV1(nisetaiDataOfKansen.sizeCV_c2)"
                                :items="selectionCvt"
                                hide-details
                                plain
                                outlined
                                dense
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-combobox>
                            </td>
                            <td class="text-center" width="12%"  v-if="edit.type !='Zenryou'">
                            <v-text-field
                                readonly
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="12%"  v-else>
                            <v-text-field
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="nisetaiDataOfKansen.quantity_c2"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                            <v-text-field
                                :readonly="dialogType"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                v-model="nisetaiDataOfKansen.Value_c2"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                                <v-text-field
                                    :readonly="dialogType"
                                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                    v-model="nisetaiDataOfKansen.wa_c2"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    
                                ></v-text-field>
                            </td>
                        </tr>
                        </tbody>


                        <!-- //NOTE NISETAI DATA FROM SUBCON -->
                        <tbody v-if="edit.first_request_received_date != null">
                        <tr>
                            <td rowspan="2" class="text-center">発注時</td>
                            <td class="text-center">電灯</td>
                            <td class="text-center" width="14%">
                            <v-text-field
                              
                                v-model="nisetaiDataFromSubcon.cc_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="13%">
                            <v-text-field
                               
                                v-model="nisetaiDataFromSubcon.kansen_blankc1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="14%">
                            <v-text-field
                                
                                @change="updatesizeCV1(edit.sizeCV_c1)"
                                v-model="nisetaiDataFromSubcon.sizeCV_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="13%">
                            <v-text-field
                                
                                v-model="nisetaiDataFromSubcon.quantity_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                            <v-text-field
                                
                                v-model="nisetaiDataFromSubcon.Value_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="13%">
                            <v-text-field
                               
                                v-model="nisetaiDataFromSubcon.wa_c1"
                                hide-details
                                plain
                                outlined
                                dense
                                readonly
                            ></v-text-field>
                            </td>
                            <td rowspan="2" class="text-center" width="20%">
                            <v-text-field
                                
                                v-model="nisetaiDataFromSubcon.eb_c1"
                                height="100%"
                                hide-details
                                plain
                                outlined
                                readonly
                            ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center" v-if="nisetaiDataFromSubcon.type !='Zenryou'">余剰</td>
                            <td class="text-center" v-else>全量</td>
                            <td class="text-center" width="17%">
                            <v-text-field
                               
                                v-model="nisetaiDataFromSubcon.cc_c2"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                            <v-text-field
                              
                                v-model="nisetaiDataFromSubcon.kansen_blankc2"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="17%">
                            <v-text-field
                               
                                v-model="nisetaiDataFromSubcon.sizeCV_c2"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                            <v-text-field
                               
                                v-model="nisetaiDataFromSubcon.quantity_c2"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                            <v-text-field
                                
                                v-model="nisetaiDataFromSubcon.Value_c2"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                            <v-text-field
                            
                                v-model="nisetaiDataFromSubcon.wa_c2"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                        </tr>
                        </tbody>
                        

                        
                    </template>
                    </v-simple-table>
                </v-col>
                </v-row>

                    <v-toolbar class="mt-3" flat dense color="blue grey darken-4 white--text" tile v-if="edit.nisetai> 1  && generatedNisetai">
                    <v-row align="center">
                        <v-col cols="10" class="d-flex justify-space-around">
                        <v-sheet
                            flat
                            dense
                            color="blue grey darken-4 white--text"
                            style="margin-left: 150px"
                            tile
                        >
                            <strong>太陽光用</strong>
                        </v-sheet>
                        </v-col>
                    </v-row>
                    </v-toolbar>
                    <v-row align="center" v-if="edit.nisetai > 1  && generatedNisetai">
                        <v-col  v-for="(item, i) in forsunLight" :key="i">
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                            <tr>
                                                <th
                                                v-for="(header, h) in Object.keys(item[0])"
                                                :key="h"
                                                class="text-center"
                                                >
                                                {{ header.toUpperCase() }}
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody v-if="edit.nisetai > 1  && generatedNisetai">
                        <tr v-for="(body, b) in item" :key="b">
                            <td rowspan="6" width="1" class="text-center">参考値</td>
                        </tr>
                        <tr>
                            <td width="20%" class="text-center">
                                <tr>
                                    <div class="select-wrapper">
                                        <v-checkbox
                                            :readonly="dialogType"
                                            v-model="nisetaiDataOfKansen.ic_c1r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-checkbox>
                                        <v-combobox
                                            :readonly="dialogType"
                                            v-model="nisetaiDataOfKansen.ic_c2r3"
                                            :items="selectionKw"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                        ></v-combobox>
                                    </div>
                                </tr>
                            </td>
                            <td width="20%" class="text-center">
                                <tr>
                                    <div class="select-wrapper">
                                        <v-checkbox
                                            :readonly="dialogType"
                                            v-model="nisetaiDataOfKansen.size_c3r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-checkbox>
                                        <v-combobox
                                            :readonly="dialogType"
                                            v-model="nisetaiDataOfKansen.size_c4r3"
                                            :items="selectionCv"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                        ></v-combobox>
                                    </div>
                                </tr>
                            </td>
                            <td class="text-center">
                                <v-text-field
                                :readonly="dialogType"
                                v-model="nisetaiDataOfKansen.total_5r3"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                v-model="nisetaiDataOfKansen.lpi_6r3"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                v-model="nisetaiDataOfKansen.fl_c7r3"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                        <!-- single text field -->
                            <td class="text-center" rowspan="5">
                                    <v-text-field
                                        :readonly="dialogType"
                                        v-model="nisetaiDataOfKansen.eb_c1r1"
                                        plain
                                        outlined
                                        dense
                                        hide-details
                                        :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                    ></v-text-field>
                            </td>
                        <tr>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    :readonly="dialogType"
                                    v-model="nisetaiDataOfKansen.ic_c1r5"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                ></v-checkbox>
                                <v-combobox
                                    :items="selectionKw"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nisetaiDataOfKansen.ic_c2r5"
                                    :readonly="dialogType"
                                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    :readonly="dialogType"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nisetaiDataOfKansen.size_c3r5"
                                    
                                ></v-checkbox>
                                <v-combobox
                                    :readonly="dialogType"
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nisetaiDataOfKansen.size_c4r5"
                                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                v-model="nisetaiDataOfKansen.total_5r5"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                v-model="nisetaiDataOfKansen.lpi_6r5"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                v-model="nisetaiDataOfKansen.fl_c7r5"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                    

                        
                        <tr>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    v-model="nisetaiDataOfKansen.ic_c1r6"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                ></v-checkbox>
                                <v-combobox
                                    large
                                    :items="selectionKw"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nisetaiDataOfKansen.ic_c2r6"
                                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                :readonly="dialogType"
                                v-model="nisetaiDataOfKansen.size_c3r6"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                ></v-checkbox>
                                <v-combobox
                                :readonly="dialogType"
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nisetaiDataOfKansen.size_c4r6"
                                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                v-model="nisetaiDataOfKansen.total_5r6"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                v-model="nisetaiDataOfKansen.lpi_6r6"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                v-model="nisetaiDataOfKansen.fl_c7r6"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                        </tr>



                        <tr>
                            <td width="5%" class="text-center">
                            <span>蓄電池自立用</span>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    :readonly="dialogType"
                                    v-model="nisetaiDataOfKansen.size_c3r7"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    
                                ></v-checkbox>
                                <v-combobox
                                    v-model="nisetaiDataOfKansen.size_c4r7"
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                v-model="nisetaiDataOfKansen.total_5r7"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                v-model="nisetaiDataOfKansen.lpi_6r7"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                v-model="nisetaiDataOfKansen.fl_c7r7"
                                plain
                                outlined
                                dense
                                hide-details
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                        </tr>


                        <tr>
                            <td width="5%" class="text-center">
                            <span>給電アダプタ</span>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    v-model="nisetaiDataOfKansen.cbForcv55"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                ></v-checkbox>
                                <v-combobox
                                    v-model="nisetaiDataOfKansen.newCv55"
                                    :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                plain
                                outlined
                                dense
                                hide-details
                                v-model="nisetaiDataOfKansen.total_cv55_3"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                plain
                                outlined
                                dense
                                hide-details
                                v-model="nisetaiDataOfKansen.lpi_cv55_4"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                :readonly="dialogType"
                                
                                plain
                                outlined
                                dense
                                hide-details
                                v-model="nisetaiDataOfKansen.fl_cv55_5"
                                :style="{ backgroundColor: edit.first_request_received_date ? '#A9A9A9' : '' }"
                            ></v-text-field>
                            </td>
                        </tr>
                        </tbody>

                        <!-- //NOTE  NISETAI RECEIVE FROM SUBCON -->

                        <tbody v-if="edit.first_request_received_date != null">
                        <tr v-for="(body, b) in item" :key="b">
                            <td rowspan="6" width="1" class="text-center">参考値</td>
                        </tr>
                        <tr>
                            <td width="20%" class="text-center">
                                <tr>
                                    <div class="select-wrapper">
                                        <v-checkbox
                                            v-model="nisetaiDataFromSubcon.ic_c1r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                        ></v-checkbox>
                                        <v-combobox
                                            v-model="nisetaiDataFromSubcon.ic_c2r3"
                                            :items="selectionKw"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                        ></v-combobox>
                                    </div>
                                </tr>
                            </td>
                            <td width="20%" class="text-center">
                                <tr>
                                    <div class="select-wrapper">
                                        <v-checkbox
                                            
                                            v-model="nisetaiDataFromSubcon.size_c3r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                        ></v-checkbox>
                                        <v-combobox
                                            
                                            v-model="nisetaiDataFromSubcon.size_c4r3"
                                            :items="selectionCv"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                        ></v-combobox>
                                    </div>
                                </tr>
                            </td>
                            <td class="text-center">
                                <v-text-field
                                
                                v-model="nisetaiDataFromSubcon.total_5r3"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                v-model="nisetaiDataFromSubcon.lpi_6r3"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                v-model="nisetaiDataFromSubcon.fl_c7r3"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <!-- single text field -->
                            <td class="text-center" rowspan="5">
                                    <v-text-field
                                        v-model="eb_c1r1"
                                        plain
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                    ></v-text-field>
                            </td>
                        <tr>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    v-model="nisetaiDataFromSubcon.ic_c1r5"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-checkbox>
                                <v-combobox
                                    :items="selectionKw"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nisetaiDataFromSubcon.ic_c2r5"
                                    readonly
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    readonly
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nisetaiDataFromSubcon.size_c3r5"
                                ></v-checkbox>
                                <v-combobox
                                    readonly
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nisetaiDataFromSubcon.size_c4r5"
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly
                                v-model="nisetaiDataFromSubcon.total_5r5"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly
                                v-model="nisetaiDataFromSubcon.lpi_6r5"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly    
                                v-model="nisetaiDataFromSubcon.fl_c7r5"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                        

                        
                        <tr>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    readonly
                                    v-model="nisetaiDataFromSubcon.ic_c1r6"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                ></v-checkbox>
                                <v-combobox
                                    large
                                    :items="selectionKw"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nisetaiDataFromSubcon.ic_c2r6"
                                    readonly
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                readonly
                                v-model="nisetaiDataFromSubcon.size_c3r6"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                ></v-checkbox>
                                <v-combobox
                                readonly
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nisetaiDataFromSubcon.size_c4r6"
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                v-model="nisetaiDataFromSubcon.total_5r6"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly
                                v-model="nisetaiDataFromSubcon.lpi_6r6"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly
                                v-model="nisetaiDataFromSubcon.fl_c7r6"
                                plain
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            </td>
                        </tr>



                        <tr>
                            <td width="5%" class="text-center">
                            <span>蓄電池自立用</span>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    v-model="nisetaiDataFromSubcon.size_c3r7"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-checkbox>
                                <v-combobox
                                    v-model="nisetaiDataFromSubcon.size_c4r7"
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                v-model="total_5r7"
                                readonly
                                plain
                                outlined
                                dense
                                hide-details
                                
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                              
                                v-model="nisetaiDataFromSubcon.lpi_6r7"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                
                                v-model="nisetaiDataFromSubcon.fl_c7r7"
                                plain
                                outlined
                                dense
                                hide-details
                                readonly
                            ></v-text-field>
                            </td>
                        </tr>


                        <tr>
                            <td width="5%" class="text-center">
                            <span>給電アダプタ</span>
                            </td>
                            <td width="14%" class="text-center">
                            <tr>
                                <div class="select-wrapper">
                                <v-checkbox
                                    v-model="cbForcv55"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-checkbox>
                                <v-combobox
                                    v-model="nisetaiDataFromSubcon.newCv55"
                                    :items="selectionCv"
                                    plain
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                ></v-combobox>
                                </div>
                            </tr>
                            </td>
                            <td class="text-center">
                            <v-text-field
                                readonly
                                plain
                                outlined
                                dense
                                hide-details
                                v-model="nisetaiDataFromSubcon.total_cv55_3"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                            readonly
                                plain
                                outlined
                                dense
                                hide-details
                                v-model="nisetaiDataFromSubcon.lpi_cv55_4"
                            ></v-text-field>
                            </td>
                            <td class="text-center">
                            <v-text-field
                             
                                plain
                                outlined
                                dense
                                hide-details
                                v-model="nisetaiDataFromSubcon.fl_cv55_5"
                                readonly
                            ></v-text-field>
                            </td>
                        </tr>
                        </tbody>


                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>

                </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions v-if="Type == 'Edit'">
                    <v-btn @click="saveProposal()" class="blue grey darken-4 white--text" tile block :hidden="this.edit.order_status == 'No Request'">
                    <v-icon>mdi-content-save</v-icon>SAVE
                    </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { bus } from "../main";
import axios from "axios";
import { message } from "ant-design-vue";
import Swal from "sweetalert2";

import {
    nisetaiArrayProposeKansen,
    ArrayReference,
    ArrayProposeKansen,
    ArraymainLine,
    ArrayforsunLight,
    ArrayWattsReference
} from "../components/Reference";
export default {
    props: ["Type", "getSavedDate"],

    data: () => ({
    statusOfOrder:'',
    nisetai_mb_ksc1:'',
    nisetai_size_ksc1:'',
   
    generatedNisetai: false,
    nisetaiDataOfKansen:{},
    dataFromSubcon:{},
    nisetaiDataFromSubcon:{},
    proposalData: [],
    oldData: [],
    objectToCompare: [],
    kansenEditData: [],
    kansenData: [],
    saveData: [],
    sendingRequest: false,
    HacchuCode: {},
    reference: ArrayReference,
    proposeKasenNisetai :nisetaiArrayProposeKansen,
    proposeKansen: ArrayProposeKansen,
    mainLine: ArraymainLine,
    forsunLight: ArrayforsunLight,
    edit: {},
    wa_c1: "",
    Value_c1: "",
    lenght_Ksc1: "",
    size_ksc2: "",
    mb_ksc2: "",
    superTotal: "",
    textColor: "black",
    dialogType: false,
    showError: false,
    menu1: false,
    menu2: false,
    editDialog: false,
    ic_cb1: false,
    kansen_blankc1: "",
    // quantity_c2: this.edit.type == 'Zenryou' ? 1 :null,
    quantity_c1: "",
    eb_c1r1: "",
    total_5r7: "",
    eb_c1: "",
    cc_c3: "",
    cbForcv55:true,
    kansen_blankc3: "",
    mb_ksc1: "",
    size_ksc1: "",
    selectionKw: ["3.0KW", "4.0KW", "5.5KW", "5.9KW", "8.0KW", "9.9KW", "KW"],
    selectionCategory: [
        "30A",
        "40A",
        "50A",
        "60A",
        "10KVA",
        "12KVA",
        "15KVA",
        "18KVA",
        "19KVA",
        "20KVA",
        "21KVA",
        "22KVA",
        "23KVA",
        "24KVA",
        "25KVA",
    ],
    selectionCvt: ["CV8", "CVT14", "CVT22", "CVT38", "CVT60"],
    selectionSpecification: ["Standard", "Unitwiring"],
    selectionCv: ["CV5.5", "CV8", "CV14", "CV22"],
    aftersavingStatusOfOrder:['For Sending','New Request','On-going']
    //variable here
    }),

    methods: {
        formatPrice(value) {
            let val = value.toString().replace(",", ".");
            if (!val || val.charAt(0) === "0") {
            return value.toFixed(3);
        } else {
            const roundedNumber = parseFloat(value.toFixed(2));
            return roundedNumber.toFixed(2);
        }
    },


        comTotal(lenght_Ksc1, size_ksc1, mb_ksc1) {
            const length = lenght_Ksc1;
            const size = size_ksc1;
            const mb = mb_ksc1;
            // const total = 17.8 * length * mb;
            // const mbdivide = 1000 * size;
            // const superTotal = total / mbdivide;
            // return superTotal  - 0.318;
            return (17.8 * length * mb) / (1000 * size);
        },


        nisetaiComTotal(nisetai_lenght_Ksc1, nisetai_size_ksc1, nisetai_mb_ksc1) {
            const length = nisetai_lenght_Ksc1;
            const size = nisetai_size_ksc1;
            const mb = nisetai_mb_ksc1;
           
          
            return (17.8 * length * mb) / (1000 * size);
        },

    


        nisetaiUpdatecc1() {
            if (this.nisetaiDataOfKansen.cc_c1 == "30A") {
                this.nisetaiDataOfKansen.nisetai_mb_ksc1 = 30; 
                this.nisetaiDataOfKansen.kansen_blankc1 = "30A";
                this.nisetaiDataOfKansen.cc_c3 = "30A";
                this.nisetaiDataOfKansen.kansen_blankc3 = "30A";
                this.nisetai_mb_ksc1 = "30";
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "40A") {
                this.nisetai_mb_ksc1 = 40;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "40A"),
                (this.nisetaiDataOfKansen.cc_c3 = "40A"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "40A"),
                (this.nisetai_mb_ksc1 = "40");
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "50A") {
                this.nisetai_mb_ksc1 = 50;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "50A"),
                (this.nisetaiDataOfKansen.cc_c3 = "50A"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "50A"),
                (this.nisetai_mb_ksc1 = "50");
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "60A") {
                this.nisetai_mb_ksc1 = 60;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "60A"),
                (this.nisetaiDataOfKansen.cc_c3 = "60A"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "60A"),
                (this.nisetai_mb_ksc1 = "60");
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "8KVA") {
                this.nisetai_mb_ksc1 = 40;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "40A"),
                (this.nisetaiDataOfKansen.cc_c3 = "8KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "40A"),
                (this.nisetai_mb_ksc1 = "40");
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "10KVA") {
                this.nisetai_mb_ksc1 = 50;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "50A"),
                (this.nisetaiDataOfKansen.cc_c3 = "10KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "50A"),
                (this.nisetai_mb_ksc1 = "50");
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "12KVA") {
                this.nisetai_mb_ksc1 = 60;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "60A"),
                (this.nisetaiDataOfKansen.cc_c3 = "12KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "60A"),
                (this.nisetai_mb_ksc1 = "60");
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
               
            } else if (this.nisetaiDataOfKansen.cc_c1 == "15KVA") {
                this.nisetai_mb_ksc1 = 75;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "75A"),
                (this.nisetaiDataOfKansen.cc_c3 = "15KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "75A"),
                (this.nisetai_mb_ksc1 = "75");
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "18KVA") {
                this.nisetai_mb_ksc1 = 100;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "100A"),
                (this.nisetaiDataOfKansen.cc_c3 = "18KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "100A"),
                (this.nisetai_mb_ksc1 = "100");
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "19KVA") {
                this.nisetai_mb_ksc1 = 100;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "100A"),
                (this.nisetaiDataOfKansen.cc_c3 = "19KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "100A"),
                (this.nisetai_mb_ksc1 = "100");
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "20KVA") {
                this.nisetai_mb_ksc1 = 100;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "100A"),
                (this.nisetaiDataOfKansen.cc_c3 = "20KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "100A"),
                (this.nisetai_mb_ksc1 = "100"),
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "21KVA") {
                this.nisetai_mb_ksc1 = 100;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "100A"),
                (this.nisetaiDataOfKansen.cc_c3 = "21KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "100A"),
                (this.nisetai_mb_ksc1 = "100"),
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "22KVA") {
                this.nisetai_mb_ksc1 = 100;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "100A"),
                (this.nisetaiDataOfKansen.cc_c3 = "22KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "100A"),
                (this.nisetai_mb_ksc1 = "100"),
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "23KVA") {
                this.nisetai_mb_ksc1 = 100;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "100A"),
                (this.nisetaiDataOfKansen.cc_c3 = "23KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "100A"),
                (this.nisetai_mb_ksc1 = "100"),
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "24KVA") {
                this.nisetai_mb_ksc1 = 100;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "100A"),
                (this.nisetaiDataOfKansen.cc_c3 = "24KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "100A"),
                (this.nisetai_mb_ksc1 = "100"),
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            } else if (this.nisetaiDataOfKansen.cc_c1 == "25KVA") {
                this.nisetai_mb_ksc1 = 100;
                (this.nisetaiDataOfKansen.kansen_blankc1 = "100A"),
                (this.nisetaiDataOfKansen.cc_c3 = "25KVA"),
                (this.nisetaiDataOfKansen.kansen_blankc3 = "100A"),
                (this.nisetai_mb_ksc1 = "100"),
                (this.nisetaiDataOfKansen.quantity_c1 = "1"),
                (this.nisetaiDataOfKansen.eb_c1 = "2"),
                (this.nisetaiDataOfKansen.eb_c1r1 = "2"),
                (this.nisetaiDataOfKansen.total_5r7 = "1");
            }
        },


        updatecc1() {
            if (this.edit.cc_c1 == "30A") {
                this.mb_ksc1 = 30; //
                this.kansen_blankc1 = "30A";
                this.cc_c3 = "30A";
                this.kansen_blankc3 = "30A";
                this.mb_ksc1 = "30";
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "40A") {
                this.mb_ksc1 = 40;
                (this.kansen_blankc1 = "40A"),
                (this.cc_c3 = "40A"),
                (this.kansen_blankc3 = "40A"),
                (this.mb_ksc1 = "40");
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "50A") {
                this.mb_ksc1 = 50;
                (this.kansen_blankc1 = "50A"),
                (this.cc_c3 = "50A"),
                (this.kansen_blankc3 = "50A"),
                (this.mb_ksc1 = "50");
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "60A") {
                this.mb_ksc1 = 60;
                (this.kansen_blankc1 = "60A"),
                (this.cc_c3 = "60A"),
                (this.kansen_blankc3 = "60A"),
                (this.mb_ksc1 = "60");
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "8KVA") {
                this.mb_ksc1 = 40;
                (this.kansen_blankc1 = "40A"),
                (this.cc_c3 = "8KVA"),
                (this.kansen_blankc3 = "40A"),
                (this.mb_ksc1 = "40");
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "10KVA") {
                this.mb_ksc1 = 50;
                (this.kansen_blankc1 = "50A"),
                (this.cc_c3 = "10KVA"),
                (this.kansen_blankc3 = "50A"),
                (this.mb_ksc1 = "50");
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "12KVA") {
                this.mb_ksc1 = 60;
                (this.kansen_blankc1 = "60A"),
                (this.cc_c3 = "12KVA"),
                (this.kansen_blankc3 = "60A"),
                (this.mb_ksc1 = "60");
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
               
            } else if (this.edit.cc_c1 == "15KVA") {
                this.mb_ksc1 = 75;
                (this.kansen_blankc1 = "75A"),
                (this.cc_c3 = "15KVA"),
                (this.kansen_blankc3 = "75A"),
                (this.mb_ksc1 = "75");
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "18KVA") {
                this.mb_ksc1 = 100;
                (this.kansen_blankc1 = "100A"),
                (this.cc_c3 = "18KVA"),
                (this.kansen_blankc3 = "100A"),
                (this.mb_ksc1 = "100");
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "19KVA") {
                this.mb_ksc1 = 100;
                (this.kansen_blankc1 = "100A"),
                (this.cc_c3 = "19KVA"),
                (this.kansen_blankc3 = "100A"),
                (this.mb_ksc1 = "100");
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "20KVA") {
                this.mb_ksc1 = 100;
                (this.kansen_blankc1 = "100A"),
                (this.cc_c3 = "20KVA"),
                (this.kansen_blankc3 = "100A"),
                (this.mb_ksc1 = "100"),
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "21KVA") {
                this.mb_ksc1 = 100;
                (this.kansen_blankc1 = "100A"),
                (this.cc_c3 = "21KVA"),
                (this.kansen_blankc3 = "100A"),
                (this.mb_ksc1 = "100"),
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "22KVA") {
                this.mb_ksc1 = 100;
                (this.kansen_blankc1 = "100A"),
                (this.cc_c3 = "22KVA"),
                (this.kansen_blankc3 = "100A"),
                (this.mb_ksc1 = "100"),
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "23KVA") {
                this.mb_ksc1 = 100;
                (this.kansen_blankc1 = "100A"),
                (this.cc_c3 = "23KVA"),
                (this.kansen_blankc3 = "100A"),
                (this.mb_ksc1 = "100"),
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "24KVA") {
                this.mb_ksc1 = 100;
                (this.kansen_blankc1 = "100A"),
                (this.cc_c3 = "24KVA"),
                (this.kansen_blankc3 = "100A"),
                (this.mb_ksc1 = "100"),
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            } else if (this.edit.cc_c1 == "25KVA") {
                this.mb_ksc1 = 100;
                (this.kansen_blankc1 = "100A"),
                (this.cc_c3 = "25KVA"),
                (this.kansen_blankc3 = "100A"),
                (this.mb_ksc1 = "100"),
                (this.quantity_c1 = "1"),
                (this.eb_c1 = "2"),
                (this.eb_c1r1 = "2"),
                (this.total_5r7 = "1");
            }
        },


        updatesizeCV1() {
            if (this.edit.sizeCV_c1 == "CV8") {
                this.size_ksc1 = 8;
                this.size_ksc1 = "8";
            } else if (this.edit.sizeCV_c1 == "CVT14") {
                this.size_ksc1 = 14;
                this.size_ksc1 = "14";
            } else if (this.edit.sizeCV_c1 == "CVT22") {
                this.size_ksc1 = 22;
                this.size_ksc1 = "22";
            } else if (this.edit.sizeCV_c1 == "CVT38") {
                this.size_ksc1 = 38;
                this.size_ksc1 = "38";
            } else if (this.edit.sizeCV_c1 == "CVT60") {
                this.size_ksc1 = 60;
                this.size_ksc1 = "60";
            }
            // console.log(this.size_ksc1,'LINE3116');
        },

        nisetaiUpdatesizeCV1() {
            
                if (this.nisetaiDataOfKansen.sizeCV_c1 == "CV8") {
                    this.nisetai_size_ksc1 = 8;
                
                } else if (this.nisetaiDataOfKansen.sizeCV_c1 == "CVT14") {
                    this.nisetai_size_ksc1 = 14;
                
                } else if (this.nisetaiDataOfKansen.sizeCV_c1 == "CVT22") {
                    this.nisetai_size_ksc1 = 22;
                
                } else if (this.nisetaiDataOfKansen.sizeCV_c1 == "CVT38") {
                    this.nisetai_size_ksc1 = 38;
                
                } else if (this.nisetaiDataOfKansen.sizeCV_c1 == "CVT60") {
                    this.nisetai_size_ksc1 = 60;
                
                }
                // this.nisetai_size_ksc1,'LINE3136'
        //  console.log();
            
        },

//NOTE loadDataFromSubcon
        loadDataFromSubcon(code_number){
            axios({
                method: 'get',
                url: `${this.$BaseUrl}/api/getSubconData/${code_number}`,
                headers: {
                'x-api-key': this.awsHeaders
                }
            }).then(res => {
                this.kansenData = res.data
                const filteredDataOfOrder = res.data.filter(element => element.code_number == code_number)
          
                this.dataFromSubcon = Object.assign({}, ...filteredDataOfOrder);
              
                // console.log(this.dataFromSubcon,'LINE3287');

            }).catch(error => {
            console.error("Error fetching data:", error);
            });
            
        },
    


        close() {
        this.editDialog = false;
        this.clear();
        },

        clear() {
        this.edit = {};
        },

        UpdateKansenNisetai(){
            this.nisetaiDataOfKansen.nisetaiCom4Val = this.nisetaiCom4Val
            this.nisetaiDataOfKansen.nisetai_mb_ksc1 = this.nisetai_mb_ksc1
            this.nisetaiDataOfKansen.nisetai_size_ksc1 = this.nisetai_size_ksc1

            axios({
                            method: 'post',
                            url: `${this.$BaseUrl}/api/insertKansenNisetaiData`,
                            data: this.nisetaiDataOfKansen,
                            headers: {
                                'x-api-key': this.awsHeaders
                            }
                        });
        },

     async   generateNisetaiData(){

            this.nisetaiDataOfKansen.nisetaiCom4Val = this.nisetaiCom4Val
            this.nisetaiDataOfKansen.nisetai_mb_ksc1 = this.nisetai_mb_ksc1
            this.nisetaiDataOfKansen.nisetai_size_ksc1 = this.nisetai_size_ksc1
            this.nisetaiDataOfKansen.nisetai_form = true


            this.edit = {
            ...this.edit,
            lenght_Ksc1: parseInt(document.getElementById("edit.lenght_Ksc1").innerHTML),
            size_ksc1: parseInt(document.getElementById("size_ksc1").innerHTML),
            mb_ksc1: parseInt(document.getElementById("mb_ksc1").innerHTML),
            size_ksc2: parseInt(document.getElementById("size_ksc2").innerHTML),
            mb_ksc2: document.getElementById("mb_ksc2").innerHTML,
            cc_c1: this.edit.cc_c1,
            kansen_blankc1: this.kansen_blankc1,
            sizeCV_c1: this.edit.sizeCV_c1,
            quantity_c1: parseInt(this.quantity_c1),
            Value_c1: this.edit.Value_c1,
            wa_c1: this.wa_c1,
            eb_c1: parseInt(this.eb_c1),
            cc_c2: this.edit.cc_c2,
            kansen_blankc2: this.edit.kansen_blankc2,
            sizeCV_c2: this.edit.sizeCV_c2,
            quantity_c2: this.edit.type == 'Zenryou' ? 1 : this.edit.quantity_c2,
            Value_c2: this.edit.Value_c2,
            wa_c2: this.edit.wa_c2,
            cc_c3: this.edit.cc_c3,
            kansen_blankc3: this.kansen_blankc3,
            sizeCV_c3: this.edit.sizeCV_c3,
            quantity_c3: this.edit.quantity_c3,
            Value_c3: this.edit.Value_c3,
            wa_c3: this.edit.wa_c3,
            eb_c3: this.edit.eb_c3,
            cc_c4: this.edit.cc_c4,
            kansen_blankc4: this.edit.kansen_blankc4,
            sizeCV_c4: this.edit.sizeCV_c4,
            quantity_c4: this.edit.quantity_c4,
            Value_c4: this.edit.Value_c4,
            wa_c4: this.edit.wa_c4,
            ic_cb1: this.edit.ic_cb1 == true ? 1 : 0,
            ic_c2: this.edit.ic_c2,
            size_cb1: this.edit.size_cb1 == true ? 1 : 0,
            size_cvt1: this.edit.size_cvt1,
            total_c1: this.edit.total_c1,
            lpi_c1: this.edit.lpi_c1,
            fl_c1: this.edit.fl_c1,
            eb_c1r1: parseInt(this.eb_c1r1),
            ic_c1r2: this.edit.ic_c1r2 == true ? 1 : 0,
            ic_c2r2: this.edit.ic_c2r2,
            size_c3r2: this.edit.size_c3r2 == true ? 1 : 0,
            size_c4r2: this.edit.size_c4r2,
            total_5r2: this.edit.total_5r2,
            lpi_6r2: this.edit.lpi_6r2,
            icfl_c7r2_c2: this.edit.fl_c7r2,
            ic_c1r3: this.edit.ic_c1r3 == true ? 1 : 0,
            ic_c2r3: this.edit.ic_c2r3,
            size_c3r3: this.edit.size_c3r3 == true ? 1 : 0,
            size_c4r3: this.edit.size_c4r3,
            total_5r3: this.edit.total_5r3,
            lpi_6r3: this.edit.lpi_6r3,
            fl_c7r3: this.edit.fl_c7r3,
            ic_c1r4: this.edit.ic_c1r4 == true ? 1 : 0,
            ic_c2r4: this.edit.ic_c2r4,
            size_c3r4: this.edit.size_c3r4 == true ? 1 : 0,
            sizeCV_c4r4: this.edit.sizeCV_c4r4,
            total_5r4: this.edit.total_5r4,
            lpi_6r4: this.edit.lpi_6r4,
            fl_c7r4: this.edit.fl_c7r4,
            ic_c1r5: this.edit.ic_c1r5 == true ? 1 : 0,
            ic_c2r5: this.edit.ic_c2r5,
            size_c3r5: this.edit.size_c3r5 == true ? 1 : 0,
            size_c4r5: this.edit.size_c4r5,
            total_5r5: this.edit.total_5r5,
            lpi_6r5: this.edit.lpi_6r5,
            fl_c7r5: this.edit.fl_c7r5,
            ic_c1r6: this.edit.ic_c1r6 == true ? 1 : 0,
            ic_c2r6: this.edit.ic_c2r6,
            size_c3r6: this.size_c3r6 == true ? 1 : 0,
            size_c4r6: this.edit.size_c4r6,
            total_5r6: this.edit.total_5r6,
            lpi_6r6: this.edit.lpi_6r6,
            fl_c7r6: this.edit.fl_c7r6,
            ic_c1r7: this.edit.ic_c1r7,
            size_c3r7: this.size_c3r7 == true ? 1 : 0,
            size_c4r7: this.edit.size_c4r7,
            total_5r7: parseInt(this.total_5r7),
            lpi_6r7: this.edit.lpi_6r7,
            fl_c7r7: this.edit.fl_c7r7,
            cbForcv55: this.cbForcv55 == true ? 1 : 0,
            newCv55: this.edit.newCv55,
            nisetai_form: true
        };

        // console.log(this.edit,'test');
        const nisetaiOfKansen = await axios({
                method: 'post',
                url: `${this.$BaseUrl}/api/insertKansenNisetaiDataFirst`,
                data: this.edit,
                headers: {
                    'x-api-key': this.awsHeaders
            }
            })

        const insertNisetaiDatainSubconOrder = axios({
                        method: 'post',
                        url: `${this.$BaseUrl}/api/insertNisetaiDataSubconORder`,
                        data: this.edit,
                        headers: {
                            'x-api-key': this.awsHeaders
                        }
                    });    
                            
            Promise.all([nisetaiOfKansen,insertNisetaiDatainSubconOrder,]).then(()=>{
                            message.success({
                                        bottom: '100px',
                                        duration: 2,
                                        maxCount: 2,
                                        text: 'Nisetai Updated.',
                                    });
                                    
                                    this.generatedNisetai = true
                                
                        })
                            .catch(errors => {
                                console.error('Promise.all rejected', errors);
                            });
                this.loadNisetaiDataOfKansen(this.edit.code_number)



        },

       
    
        saveProposal() {            

        this.edit = {
            ...this.edit,
            lenght_Ksc1: parseInt(document.getElementById("edit.lenght_Ksc1").innerHTML),
            size_ksc1: parseInt(document.getElementById("size_ksc1").innerHTML),
            mb_ksc1: parseInt(document.getElementById("mb_ksc1").innerHTML),
            size_ksc2: parseInt(document.getElementById("size_ksc2").innerHTML),
            mb_ksc2: document.getElementById("mb_ksc2").innerHTML,
            cc_c1: this.edit.cc_c1,
            kansen_blankc1: this.kansen_blankc1,
            sizeCV_c1: this.edit.sizeCV_c1,
            quantity_c1: parseInt(this.quantity_c1),
            Value_c1: this.edit.Value_c1,
            wa_c1: this.wa_c1,
            eb_c1: parseInt(this.eb_c1),
            cc_c2: this.edit.cc_c2,
            kansen_blankc2: this.edit.kansen_blankc2,
            sizeCV_c2: this.edit.sizeCV_c2,
            quantity_c2: this.edit.type == 'Zenryou' ? 1 : this.edit.quantity_c2,
            Value_c2: this.edit.Value_c2,
            wa_c2: this.edit.wa_c2,
            cc_c3: this.edit.cc_c3,
            kansen_blankc3: this.kansen_blankc3,
            sizeCV_c3: this.edit.sizeCV_c3,
            quantity_c3: this.edit.quantity_c3,
            Value_c3: this.edit.Value_c3,
            wa_c3: this.edit.wa_c3,
            eb_c3: this.edit.eb_c3,
            cc_c4: this.edit.cc_c4,
            kansen_blankc4: this.edit.kansen_blankc4,
            sizeCV_c4: this.edit.sizeCV_c4,
            quantity_c4: this.edit.quantity_c4,
            Value_c4: this.edit.Value_c4,
            wa_c4: this.edit.wa_c4,
            ic_cb1: this.edit.ic_cb1 == true ? 1 : 0,
            ic_c2: this.edit.ic_c2,
            size_cb1: this.edit.size_cb1 == true ? 1 : 0,
            size_cvt1: this.edit.size_cvt1,
            total_c1: this.edit.total_c1,
            lpi_c1: this.edit.lpi_c1,
            fl_c1: this.edit.fl_c1,
            eb_c1r1: parseInt(this.eb_c1r1),
            ic_c1r2: this.edit.ic_c1r2 == true ? 1 : 0,
            ic_c2r2: this.edit.ic_c2r2,
            size_c3r2: this.edit.size_c3r2 == true ? 1 : 0,
            size_c4r2: this.edit.size_c4r2,
            total_5r2: this.edit.total_5r2,
            lpi_6r2: this.edit.lpi_6r2,
            icfl_c7r2_c2: this.edit.fl_c7r2,
            ic_c1r3: this.edit.ic_c1r3 == true ? 1 : 0,
            ic_c2r3: this.edit.ic_c2r3,
            size_c3r3: this.edit.size_c3r3 == true ? 1 : 0,
            size_c4r3: this.edit.size_c4r3,
            total_5r3: this.edit.total_5r3,
            lpi_6r3: this.edit.lpi_6r3,
            fl_c7r3: this.edit.fl_c7r3,
            ic_c1r4: this.edit.ic_c1r4 == true ? 1 : 0,
            ic_c2r4: this.edit.ic_c2r4,
            size_c3r4: this.edit.size_c3r4 == true ? 1 : 0,
            sizeCV_c4r4: this.edit.sizeCV_c4r4,
            total_5r4: this.edit.total_5r4,
            lpi_6r4: this.edit.lpi_6r4,
            fl_c7r4: this.edit.fl_c7r4,
            ic_c1r5: this.edit.ic_c1r5 == true ? 1 : 0,
            ic_c2r5: this.edit.ic_c2r5,
            size_c3r5: this.edit.size_c3r5 == true ? 1 : 0,
            size_c4r5: this.edit.size_c4r5,
            total_5r5: this.edit.total_5r5,
            lpi_6r5: this.edit.lpi_6r5,
            fl_c7r5: this.edit.fl_c7r5,
            ic_c1r6: this.edit.ic_c1r6 == true ? 1 : 0,
            ic_c2r6: this.edit.ic_c2r6,
            size_c3r6: this.size_c3r6 == true ? 1 : 0,
            size_c4r6: this.edit.size_c4r6,
            total_5r6: this.edit.total_5r6,
            lpi_6r6: this.edit.lpi_6r6,
            fl_c7r6: this.edit.fl_c7r6,
            ic_c1r7: this.edit.ic_c1r7,
            size_c3r7: this.size_c3r7 == true ? 1 : 0,
            size_c4r7: this.edit.size_c4r7,
            total_5r7: parseInt(this.total_5r7),
            lpi_6r7: this.edit.lpi_6r7,
            fl_c7r7: this.edit.fl_c7r7,
            cbForcv55: this.cbForcv55 == true ? 1 : 0,
            newCv55: this.edit.newCv55,
        };

        console.log(this.edit,'line3543');

        
    
        let dataForStatus = { codeNumber : this.edit.code_number,
                                status : 'For Sending' }

        let  dataForTest={
                code_number : this.edit.code_number,
                total_cv55_3:this.edit.total_cv55_3,
                lpi_cv55_4: this.edit.lpi_cv55_4,
                fl_cv55_5: this.edit.fl_cv55_5,
            }
            
            let  zenryouData={
                code_number : this.edit.code_number,
                quantity_c2:this.edit.quantity_c2,
                sizeCV_c2: this.edit.sizeCV_c2,
            } 
        const zenRyouUpdatedData =   axios({
                    method: 'post',
                    url: `${this.$BaseUrl}/api/updateHacchuDataZenryouPlan`,
                    data: zenryouData,
                    headers: {
                        'x-api-key': this.awsHeaders
                    }
                });       

        const dataForAdditionalField =   axios({
                        method: 'post',
                        url: `${this.$BaseUrl}/api/UpdateAdditionalTextField`,
                        data: dataForTest,
                        headers: {
                            'x-api-key': this.awsHeaders
                        }
                    });

        const addSendingStatusRequestForManualInput = axios({
                method: 'post',
                url: `${this.$BaseUrl}/api/addSendingStatusinManualInput`,
                data: dataForStatus,
                headers: {
                    'x-api-key': this.awsHeaders
                }
            });
                    
        Swal.fire({
                icon:'question',
                title: 'Confirm to save proposal',
                text:'When the data is saved, you can then proceed to send it to the subcon',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText : 'Cancel',
                allowOutsideClick: () => {
                const popup = this.$swal.getPopup()
                popup.classList.remove('swal2-show')
                return false
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    if (this.kansenEditData.length > 0) {
                    
                    axios({
                            method: 'post',
                            url: `${this.$BaseUrl}/api/updateKansenProposalEditForm`,
                            data: this.edit,
                            headers: {
                                'x-api-key': this.awsHeaders
                            }
                            }).then(() => {
                                    message.success({
                                        bottom: '100px',
                                        duration: 3,
                                        maxCount: 3,
                                        text: 'Data update successful',
                                    });
                                    this.editDialog=false
                                    setTimeout(() => {
                                            location.reload()
                                            }, 2000);
                                    
                                })
                                .catch(error => {
                                    console.error(error);
                                
                            });
                    } else if( parseInt(this.edit.nisetai) > 1) {
                        
                        const insertRequest = axios({
                            method: 'post',
                            url: `${this.$BaseUrl}/api/insertKansenProposalEditForm`,
                            data: this.edit,
                            headers: {
                                'x-api-key': this.awsHeaders
                            }
                        });
                    
                        const addSendingStatusRequest = axios({
                            method: 'post',
                            url: `${this.$BaseUrl}/api/addSendingStatus`,
                            data: dataForStatus,
                            headers: {
                                'x-api-key': this.awsHeaders
                            }
                        });

                        

                                Promise.all([insertRequest,addSendingStatusRequest,dataForAdditionalField,zenRyouUpdatedData,addSendingStatusRequestForManualInput]).then(()=>{
                                            message.success({
                                                        bottom: '100px',
                                                        duration: 2,
                                                        maxCount: 2,
                                                        text: 'Sending Button Enable.',
                                                    });
                                                    
                                                    setTimeout(() => {
                                                    this.UpdateKansenNisetai()
                                                    location.reload()
                                                    }, 2000);
                                                
                                        })
                                            .catch(errors => {
                                                console.error('Promise.all rejected', errors);
                                            });


                    }else{

                        const insertRequest = axios({
                            method: 'post',
                            url: `${this.$BaseUrl}/api/insertKansenProposalEditForm`,
                            data: this.edit,
                            headers: {
                                'x-api-key': this.awsHeaders
                            }
                        });
                    
                        const addSendingStatusRequest = axios({
                            method: 'post',
                            url: `${this.$BaseUrl}/api/addSendingStatus`,
                            data: dataForStatus,
                            headers: {
                                'x-api-key': this.awsHeaders
                            }
                        });

                        Promise.all([insertRequest, addSendingStatusRequest,dataForAdditionalField,zenRyouUpdatedData,addSendingStatusRequestForManualInput]).then(()=>{
                            message.success({
                                        bottom: '100px',
                                        duration: 2,
                                        maxCount: 2,
                                        text: 'Sending Button Enable.',
                                    });
                                    setTimeout(() => {
                                    location.reload()
                                    }, 2000);
                        })
                            .catch(errors => {
                                console.error('Promise.all rejected', errors);
                            });
                    }
                    
                        this.editDialog=false
            
                }else{
                    return false
                }
            })   

    }, // End of Save Data

    calculate() {
        let sum = 0;
        let numbers = this.edit.Value_c1.split("+");
        for (let i = 0; i < numbers.length; i++) {
            sum += parseInt(numbers[i]);
        }
        this.wa_c1 = sum ? sum : "";
    },

    nisetaiCalculate() {
        let sum = 0;
        let numbers = this.nisetaiDataOfKansen.Value_c1.split("+");
        for (let i = 0; i < numbers.length; i++) {
            sum += parseInt(numbers[i]);
        }
        this.nisetaiDataOfKansen.wa_c1 = sum ? sum : "";
    },




    loadUwKansenEditData(id) {
        axios({
                    method: 'get',
                    url: `${this.$BaseUrl}/api/getKansenEditData/${id}`,
                    headers: {
                        'x-api-key': this.awsHeaders
                    }
                    }).then(res => {
                        this.kansenEditData = res.data
                        // console.log(res.data ,'this is the data');
                        
                    })
            },


    loadNisetaiDataFromSubcon(codeNumber){
        axios({
            method: 'get',
            url: `${this.$BaseUrl}/api/getNisetaiData/${codeNumber}`,
            headers: {
                'x-api-key': this.awsHeaders
            }
            }).then(res => {
                const nisetaiObject = res.data.reduce((result, currentObject) => {
                Object.assign(result, currentObject);
                        return result;
                }, {});
                this.nisetaiDataFromSubcon = nisetaiObject
                
            })
    },

    loadNisetaiDataOfKansen(codeNumber){
        axios({
            method: 'get',
            url: `${this.$BaseUrl}/api/getUwNisetaiData/${codeNumber}`,
            headers: {
                'x-api-key': this.awsHeaders
            }
            }).then(res => {
                // console.log(res.data.length,'SS'); //length
                if(res.data.length > 0){
                    const nisetaiObject = res.data.reduce((result, currentObject) => {
                        Object.assign(result, currentObject);
                                return result;
                        }, {});
                        this.nisetaiDataOfKansen = nisetaiObject
                        this.generatedNisetai = true;
                }else{ 
                        this.generatedNisetai = false;
                }
            })
    },


    loadEditedKanasenProposal(codeNumber){
        axios({
            method: 'get',
            url: `${this.$BaseUrl}/api/getUpdatedKansenProposal/${codeNumber}`,
            headers: {
                'x-api-key': this.awsHeaders
            }
            }).then(res => {
                const updatedObject = res.data.reduce((result, currentObject) => {
                Object.assign(result, currentObject);
                        return result;
                }, {});
                
                const filteredStatuses = this.aftersavingStatusOfOrder.filter(status => {
                    return status !== 'For Sending';
                    }); 
                    // || filteredStatuses.includes(this.edit.order_status)
            
                    if(this.edit.finish_first_request != null || filteredStatuses.includes(this.edit.order_status)){
                        updatedObject.first_request_received_date = 1
                    }
                // this.edit.first_request_received_date = this.edit.first_request_received_date
                this.kansen_blankc1 = updatedObject.kansen_blankc1
                this.edit = updatedObject

            })
    },

  }, //END OF METHODS
mounted() {

   
    bus.$on('editDialog' , (res) =>{
            this.editDialog = true;

            Object.assign(this.edit, res);   

            // console.log(this.edit.kansenProposal_status,'3820000');
            // console.log(this.edit.order_status);
        
            if(this.aftersavingStatusOfOrder.includes(this.edit.kansenProposal_status) || this.aftersavingStatusOfOrder.includes(this.edit.order_status)){
                    this.loadEditedKanasenProposal(this.edit.code_number)
                
            }else if(this.edit.finish_first_request != null){
                this.loadEditedKanasenProposal(this.edit.code_number)
                this.loadDataFromSubcon(this.edit.code_number)
            }

                const wattsDetailsvalue = (arr, w, el, origVal) => {
                    let value = arr.wattsDetails.filter((el) => el.watts == w)[0][el];
                    let res = "";
                    if (arr.wattsDetails.filter((el) => el.watts == w)[0]["details1"] != "") {
                        res = value;
                    } else {
                        res = origVal;
                    }
                    return res;
                };

                const forArrayWattsReference = (obj) => {
                    for(let watts of ArrayWattsReference){
                        let {data , data3} = watts

                        for(let val of data ){
                            let { name , details } = val
                            obj[data3] =  res.MeterInfo[data3]
                            obj[name] = wattsDetailsvalue(
                                res,
                                res.MeterInfo[data3],
                                details,
                                res.MeterInfo[name]
                            );
                        }
                    }
                }
                forArrayWattsReference(this.edit)

                
        

                // 蓄電池自立用
                this.edit.size_c3r7 = true;
                this.edit.cbForcv55 = true;

                this.edit.ic_c2r2 = res.MeterInfo.ic_c2r2;
                this.edit.size_c4r2 = res.MeterInfo.size_c4r2;
                this.edit.ic_c2r3 = res.MeterInfo.ic_c2r3;
                this.edit.size_c4r3 = res.MeterInfo.size_c4r3;
                this.edit.ic_c2r4 = res.MeterInfo.ic_c2r4;
                this.edit.sizeCV_c4r4 = res.MeterInfo.sizeCV_c4r4;
                this.edit.ic_c2r5 = res.MeterInfo.ic_c2r5;
                this.edit.size_c4r5 = res.MeterInfo.size_c4r5;
                this.edit.ic_c2r6 = res.MeterInfo.ic_c2r6;
                this.edit.size_c4r6 = res.MeterInfo.size_c4r6;
                this.edit.ic_c1r7 = res.MeterInfo.ic_c1r7;
                this.edit.size_c4r7 = res.MeterInfo.size_c4r7;
                this.edit.newCv55 = res.MeterInfo.newCv55;

                this.nisetai_size_ksc1 = res.nisetai_size_ksc1
                this.nisetai_mb_ksc1 = parseInt(res.nisetai_mb_ksc1)

                this.statusOfOrder = res.order_status


                
                this.updatecc1();
                this.updatesizeCV1();
                this.comTotal();
                this.calculate();

                this.nisetaiComTotal();
                this.nisetaiUpdatecc1();
                

                if (this.Type == "View") {
                    this.dialogType = true;
                }

                
                this.loadUwKansenEditData(this.edit.id);
                this.loadDataFromSubcon(this.edit.code_number)
                this.loadNisetaiDataFromSubcon(this.edit.code_number)
                this.loadNisetaiDataOfKansen(this.edit.code_number)
                
             

               
                
        })

        
        
      

    
}, //end of mounted

    computed: {        
        getDivision() {
            if (this.wa_c1 == "") {
            return parseInt(this.edit.lenght_Ksc1) == "0";
            } else {
            return this.wa_c1 - 5;
            }
        },

        com4Val() {
            const comTotalValue = this.comTotal(
                this.getDivision,
                this.size_ksc1,
                this.mb_ksc1
            );
                if (comTotalValue >= 2) {
                    return "* Need to use one higher size of CVT *";
                } else {
                    return "";
                }
            },


        nisetaiGetDivision() {
            if (this.nisetaiDataOfKansen.wa_c1 == "") {
                return parseInt(this.nisetai_lenght_Ksc1) == "0";
            } else {
                return this.nisetaiDataOfKansen.wa_c1 - 5;
            }
        },

        nisetaiCom4Val() {
            const comTotalValue = this.nisetaiComTotal(
                this.nisetaiGetDivision, //22
                this.nisetai_size_ksc1,
                this.nisetai_mb_ksc1
            );
                if (comTotalValue >= 2) {
                    return "* Need to use one higher size of CVT *";
                } else {
                    return "";
                }
            },


        
    },
    watch : {

        "dataFromSubcon.fl_c7r7": {
            immediate: true,
            handler(newVal) {
                // Check if the value is not empty
                if (newVal && newVal.trim() !== "") {
                this.dataFromSubcon.size_c3r7 = true; 
                } else {
                this.dataFromSubcon.size_c3r7 = false; 
                }
            },
        },

        "dataFromSubcon.fl_c7r6": {
            immediate: true, 
            handler(newVal) {
                if (newVal && newVal.trim() !== "" && newVal && newVal.trim() !== 'undefined'){
                this.dataFromSubcon.size_c3r6= true; 
                this.dataFromSubcon.ic_c1r6 = true;
                } else {
                this.dataFromSubcon.size_c3r6 = false;
                this.dataFromSubcon.ic_c1r6 = false;
                }
            },
        },

        "dataFromSubcon.fl_c7r5": {
            immediate: true, 
            handler(newVal) {
                if (newVal && newVal.trim() !== "") {
                this.dataFromSubcon.ic_c1r5 = true; 
                this.dataFromSubcon.size_c3r5 = true;
                } else {
                this.dataFromSubcon.ic_c1r5 = false;
                this.dataFromSubcon.size_c3r5 = false;
                }
            },
        },

        "dataFromSubcon.fl_c7r4": {
            immediate: true, 
            handler(newVal) {
                if (newVal && newVal.trim() !== "") {
                this.dataFromSubcon.ic_c1r4 = true; 
                this.dataFromSubcon.size_c3r4 = true;
                } else {
                this.dataFromSubcon.ic_c1r4 = false;
                this.dataFromSubcon.size_c3r4 = false;
                }
            },
        },

        "dataFromSubcon.fl_c7r3": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.dataFromSubcon.ic_c1r3 = true; 
                this.dataFromSubcon.size_c3r3 = true;
                } else {
                this.dataFromSubcon.ic_c1r3 = false;
                this.dataFromSubcon.size_c3r3 = false;
                }
            },
        },

        "dataFromSubcon.fl_c7r2": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.dataFromSubcon.ic_c1r2 = true; 
                this.dataFromSubcon.size_c3r2 = true;
                } else {
                this.dataFromSubcon.ic_c1r2 = false;
                this.dataFromSubcon.size_c3r2 = false;
                }
            },
        },

        "dataFromSubcon.fl_c1": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.dataFromSubcon.ic_cb1 = true; 
                this.dataFromSubcon.size_cb1 = true;
                } else {
                this.dataFromSubcon.ic_cb1 = false;
                this.dataFromSubcon.size_cb1 = false;
                }
            },
        },

     //NISETAI CHECKBOX

     "nisetaiDataFromSubcon.fl_c7r7": {
            immediate: true,
            handler(newVal) {
                // Check if the value is not empty
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataFromSubcon.size_c3r7 = true; 
                } else {
                this.nisetaiDataFromSubcon.size_c3r7 = false; 
                }
            },
        },

        "nisetaiDataFromSubcon.fl_c7r6": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "" && newVal && newVal.trim() !== 'undefined'  ) {
                this.nisetaiDataFromSubcon.size_c3r6= true; 
                this.nisetaiDataFromSubcon.ic_c1r6 = true;
                } else {
                this.nisetaiDataFromSubcon.size_c3r6 = false;
                this.nisetaiDataFromSubcon.ic_c1r6 = false;
                }
            },
        },

        "nisetaiDataFromSubcon.fl_c7r5": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataFromSubcon.ic_c1r5 = true; 
                this.nisetaiDataFromSubcon.size_c3r5 = true;
                } else {
                this.nisetaiDataFromSubcon.ic_c1r5 = false;
                this.nisetaiDataFromSubcon.size_c3r5 = false;
                }
            },
        },

        "nisetaiDataFromSubcon.fl_c7r4": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataFromSubcon.ic_c1r4 = true; 
                this.nisetaiDataFromSubcon.size_c3r4 = true;
                } else {
                this.nisetaiDataFromSubcon.ic_c1r4 = false;
                this.nisetaiDataFromSubcon.size_c3r4 = false;
                }
            },
        },

        "nisetaiDataFromSubcon.fl_c7r3": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataFromSubcon.ic_c1r3 = true; 
                this.nisetaiDataFromSubcon.size_c3r3 = true;
                } else {
                this.nisetaiDataFromSubcon.ic_c1r3 = false;
                this.nisetaiDataFromSubcon.size_c3r3 = false;
                }
            },
        },

        "nisetaiDataFromSubcon.fl_c7r2": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataFromSubcon.ic_c1r2 = true; 
                this.nisetaiDataFromSubcon.size_c3r2 = true;
                } else {
                this.nisetaiDataFromSubcon.ic_c1r2 = false;
                this.nisetaiDataFromSubcon.size_c3r2 = false;
                }
            },
        },

        "nisetaiDataFromSubcon.fl_c1": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataFromSubcon.ic_cb1 = true; 
                this.nisetaiDataFromSubcon.size_cb1 = true;
                } else {
                this.nisetaiDataFromSubcon.ic_cb1 = false;
                this.nisetaiDataFromSubcon.size_cb1 = false;
                }
            },
        },




        ///START HERE


        "nisetaiDataOfKansen.fl_c7r7": {
            immediate: true,
            handler(newVal) {
                // Check if the value is not empty
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataOfKansen.size_c3r7 = true; 
                } else {
                this.nisetaiDataOfKansen.size_c3r7 = false; 
                }
            },
        },

        "nisetaiDataOfKansen.fl_c7r6": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "" && newVal && newVal.trim() !== 'undefined'  ) {
                this.nisetaiDataOfKansen.size_c3r6= true; 
                this.nisetaiDataOfKansen.ic_c1r6 = true;
                } else {
                this.nisetaiDataOfKansen.size_c3r6 = false;
                this.nisetaiDataOfKansen.ic_c1r6 = false;
                }
            },
        },

        "nisetaiDataOfKansen.fl_c7r5": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataOfKansen.ic_c1r5 = true; 
                this.nisetaiDataOfKansen.size_c3r5 = true;
                } else {
                this.nisetaiDataOfKansen.ic_c1r5 = false;
                this.nisetaiDataOfKansen.size_c3r5 = false;
                }
            },
        },

        "nisetaiDataOfKansen.fl_c7r4": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataOfKansen.ic_c1r4 = true; 
                this.nisetaiDataOfKansen.size_c3r4 = true;
                } else {
                this.nisetaiDataOfKansen.ic_c1r4 = false;
                this.nisetaiDataOfKansen.size_c3r4 = false;
                }
            },
        },

        "nisetaiDataOfKansen.fl_c7r3": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataOfKansen.ic_c1r3 = true; 
                this.nisetaiDataOfKansen.size_c3r3 = true;
                } else {
                this.nisetaiDataOfKansen.ic_c1r3 = false;
                this.nisetaiDataOfKansen.size_c3r3 = false;
                }
            },
        },

        "nisetaiDataOfKansen.fl_c7r2": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataOfKansen.ic_c1r2 = true; 
                this.nisetaiDataOfKansen.size_c3r2 = true;
                } else {
                this.nisetaiDataOfKansen.ic_c1r2 = false;
                this.nisetaiDataOfKansen.size_c3r2 = false;
                }
            },
        },

        "nisetaiDataOfKansen.fl_c1": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && newVal.trim() !== "") {
                this.nisetaiDataOfKansen.ic_cb1 = true; 
                this.nisetaiDataOfKansen.size_cb1 = true;
                } else {
                this.nisetaiDataOfKansen.ic_cb1 = false;
                this.nisetaiDataOfKansen.size_cb1 = false;
                }
            },
        },

    }
    }; 
</script>

<style>
.select-wrapper {
        display: flex;
}
.fieldclass2 {
    background-color:rgba(104, 104, 104, 0.121);
    font-weight: bold;
    color:rgb(0, 0, 0);
}
.fieldclass {
    background-color:rgba(255, 255, 255, 0.986);
    font-weight: bold;
    color:rgb(0, 0, 0);
}
</style>
